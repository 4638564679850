import { Button, Img, Page } from 'journey-ui'
import React from 'react'

type Props = {
  brand: Brand
}

const DomainUnlinkedPage: React.FC<Props> = () => (
  <Page title="Welcome to Pizzamico">
    <div className="flex justify-center items-center w-full h-full flex-col space-y-2">
      <Img size={{ w: 200, h: 70 }} alt="logo" src="/images/pizzamico-logo.png" />
      <div className="w-1/2 text-center text-lg">
        Your domain's DNS is correctly pointed to our server. However your domain has not yet been associated
        with your restaurant. Please give us a call to connect your domain.
      </div>
      <Button href="/">
        Check Again
      </Button>
    </div>
  </Page>
)

// noinspection JSUnusedGlobalSymbols (PHPStorm doesn't detect NextJS's pages)
export default DomainUnlinkedPage
